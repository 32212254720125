import React from 'react'
import Layout from '../components/Layout'
import Button from '../components/Button'
import { Link } from 'gatsby'

const Thanks = () => (

    <div className="max-w-screen-xl mx-auto mt-10 p-10 md:p-20">
      <Link className="absolute top-0 right-0 p-4 text-red text-sm uppercase tracking-widest font-semibold" to={'/'}>
            Close X
      </Link>
      <div className="mx-auto mt-10 p-5 max-w-3xl">
        <h1 className="h1 text-3xl md:text-6xl text-center">Success!</h1>
        <p className="mt-5 mb-10 text-center">
        Success! Your story has been submitted to the Legacy Quilt Project. You will receive a notification if your story is approved. Once approved, your story will be on view in the exhibition and online. 
        </p>
        <div className="text-center">
          <Link to="/">
            <Button >Browse the Quilt</Button>
          </Link>
        </div>
      </div>
    </div>

)

export default Thanks
